import i18next, { TFunctionDetailedResult } from 'i18next'
import { getCurrentLanguage } from 'utils/current-language'
import en from 'i18n/languages/en'
import pl from 'i18n/languages/pl'
import uk from 'i18n/languages/uk'
import it from 'i18n/languages/it'
import de from 'i18n/languages/de'
import cs from 'i18n/languages/cs'
import ro from 'i18n/languages/ro'
import fr from 'i18n/languages/fr'
import es from 'i18n/languages/es'
import sk from 'i18n/languages/sk'
import ru from 'i18n/languages/ru'

const translationModules: { [key: string]: any } = {
  en,
  pl,
  uk,
  it,
  de,
  cs,
  ro,
  fr,
  es,
  sk,
  ru,
}

export const initI18n = () => {
  const defaultLang = 'pl'
  const currentLanguage = getCurrentLanguage()
  const addResourceBundle = (language: string, translations) => {
    i18next.addResourceBundle(language, 'translation', translations)
  }

  const resources = Object.keys(translationModules).reduce((acc, lang) => {
    acc[lang] = {
      translation: translationModules[lang],
    }
    return acc
  }, {} as { [key: string]: { translation: any } })

  return i18next
    .init({
      lng: currentLanguage,
      fallbackLng: defaultLang,
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      resources,
      nsSeparator: '::',
    })
    .then(() => {
      switch (getCurrentLanguage()) {
        case 'en':
          return import('./languages/en').then((translations) => {
            addResourceBundle(getCurrentLanguage(), translations.default)
          })
        case 'ru':
          return import('./languages/ru').then((translations) => {
            addResourceBundle(getCurrentLanguage(), translations.default)
          })
        case 'uk':
          return import('./languages/uk').then((translations) => {
            addResourceBundle(getCurrentLanguage(), translations.default)
          })
        case 'it':
          return import('./languages/it').then((translations) => {
            addResourceBundle(getCurrentLanguage(), translations.default)
          })
        case 'es':
          return import('./languages/es').then((translations) => {
            addResourceBundle(getCurrentLanguage(), translations.default)
          })
        case 'cs':
          return import('./languages/cs').then((translations) => {
            addResourceBundle(getCurrentLanguage(), translations.default)
          })
        case 'fr':
          return import('./languages/fr').then((translations) => {
            addResourceBundle(getCurrentLanguage(), translations.default)
          })
        case 'de':
          return import('./languages/de').then((translations) => {
            addResourceBundle(getCurrentLanguage(), translations.default)
          })
        case 'ro':
          return import('./languages/ro').then((translations) => {
            addResourceBundle(getCurrentLanguage(), translations.default)
          })
        case 'sk':
          return import('./languages/sk').then((translations) => {
            addResourceBundle(getCurrentLanguage(), translations.default)
          })
        default:
          return import('./languages/pl').then((translations) => {
            addResourceBundle('pl', translations.default)
          })
      }
    })
}

export const i18n = (key, props?): TFunctionDetailedResult => i18next.t(key, props)

export const getTranslationsMap = (): any => i18next.getResourceBundle(getCurrentLanguage(), '')
