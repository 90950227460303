export default {
  highcharts: {
    decimalPoint: ',',
    downloadJPEG: 'Скачать JPG файл',
    downloadPDF: 'Скачать PDF файл',
    downloadPNG: 'Скачать PNG файл',
    downloadSVG: 'Скачать SVG файл',
    exportButtonTitle: 'Экспортировать',
    forexRate: 'Курс валют FOREX',
    loading: 'Загрузка...',
    months: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
    printButtonTitle: 'Печатать',
    rangeSelectorFrom: 'С',
    rangeSelectorTo: 'До',
    rangeSelectorZoom: 'Приблизить',
    rateTypes: {
      ask: 'Лучшее предложение по продаже',
      bid: 'Лучшее предложение по покупке',
      forex: 'Средний курс в мире',
    },
    resetZoom: 'Восстановить',
    resetZoomTitle: 'Восстановить',
    shortMonths: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    thousandsSep: ' ',
    weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    wtBestAsk: 'Лучшее предложение по продаже',
    wtBestBid: 'Лучшее предложение по покупке',
  },
  calculator: {
    title: 'Рассчитайте экономию в Walutomat',
    rangeFrom: 'Я хочу',
    forBuy: 'для',
    forSell: 'на',
    directionBuy: 'Покупка',
    directionSell: 'Продажа',
    payText: 'вы заплатите',
    sellText: 'вы получите',
    buySelect: 'купить',
    sellSelect: 'продать',
    rateText: 'Ставка',
    amountText: 'Сумма',
    includes: 'Включает',
    commission: '{{commission}}% комиссия',
    howItWorks: '',
    banksComapreOpen: 'Сравнить с другими',
    banksComapreClose: 'Свернуть список',
    savingsMonthly: 'С Walutomat вы экономите даже один раз',
    savingsYearly: 'заменяя в течение года, каждый месяц.',
    conjunction: 'и',
    serverError: 'Ошибка связи с сервером',
    unsupportedPair: 'В настоящее время мы не предлагаем курсы для выбранной валютной пары.',
    infoError: {
      exchange: 'На Walutomat.pl вы можете напрямую обменивать',
      transactionFee:
        'и обратно. Такая операция в банке связана с высокой комиссией, обычно достигающей даже 5-10% от суммы обмена.',
    },
    spinnerError: 'Возникла проблема с загрузкой данных',
  },
}
