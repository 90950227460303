export default {
  calculator: {
    title: 'Calcolo del risparmio in Walutomat',
    rangeFrom: 'Voglio',
    forBuy: 'per',
    forSell: 'a',
    directionBuy: 'Acquistando',
    directionSell: 'Vendendo',
    payText: 'pagherà',
    sellText: 'riceverà',
    buySelect: 'acquistare',
    sellSelect: 'vendere',
    rateText: 'Tasso di cambio',
    amountText: 'Importo',
    includes: 'Include',
    commission: '{{commission}}% commissione',
    howItWorks: '',
    banksComapreOpen: 'Confronta con altri',
    banksComapreClose: "Chiudi l'elenco",
    savingsMonthly: 'Risparmiate anche una sola volta con Walutomat',
    savingsYearly: "sostituendo tutto l'anno, ogni mese.'",
    conjunction: 'e',
    serverError: 'Errore di comunicazione del server',
    unsupportedPair: 'Al momento non offriamo tassi per la coppia di valute selezionata.',
    infoError: {
      exchange: 'Su Walutomat.pl è possibile scambiare direttamente',
      transactionFee:
        "e indietro. Tale transazione in banca è associata a una commissione elevata, che di solito raggiunge anche il 5-10% dell'importo del cambio.",
    },
    spinnerError: 'Si è verificato un problema nel download dei dati',
  },
}
