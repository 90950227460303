import {
  allowedFunctionalCookiesForPanel,
  allAllowedCookiesForPanel,
  allAllowedCookies,
  allowedFunctionalCookies,
} from 'config/allowedCookies'
import { AllAllowedCookies } from 'models/cookies'
import { createUrlWithParams, getAllowedCookies } from 'services/cookie-service'
import { getLangFromUrl, shouldRedirectBasedOnLang } from 'utils/current-language'

export const isPanelUrl = (url: URL): boolean =>
  url.hostname.includes('user.') || url.hostname.includes('panel.') || url.hostname.includes('biz.')

const handleConditionalRedirects = (): void => {
  const currentUrl = new URL(window.location.href)
  const currentLang = getLangFromUrl()

  document.addEventListener('click', (event) => {
    const $targetElement = event.target as HTMLElement
    const $targetAnchor = $targetElement.closest('a') as HTMLAnchorElement
    if (!$targetAnchor) return

    const targetUrl = new URL($targetAnchor.href)
    const targetLang = getLangFromUrl(targetUrl.pathname)
    if (shouldRedirectBasedOnLang(currentUrl, targetUrl, currentLang, targetLang)) {
      event.preventDefault()

      let allowedCookies: AllAllowedCookies[]
      if (window.Cookiebot?.consent?.marketing) {
        allowedCookies = isPanelUrl(targetUrl) ? allAllowedCookiesForPanel : allAllowedCookies
      } else {
        allowedCookies = isPanelUrl(targetUrl) ? allowedFunctionalCookiesForPanel : allowedFunctionalCookies
      }

      window.location.href = createUrlWithParams(getAllowedCookies(allowedCookies), targetUrl)
    }
  })
}

export const initHandleConditionalRedirects = (): void => {
  handleConditionalRedirects()
}
