export default {
  calculator: {
    title: 'Calcular el ahorro en Walutomat',
    rangeFrom: 'Quiero',
    forBuy: 'para',
    forSell: 'a',
    directionBuy: 'Comprar',
    directionSell: 'Vender',
    payText: 'usted pagará',
    sellText: 'recibirá',
    buySelect: 'comprar',
    sellSelect: 'vender',
    rateText: 'Tipo de cambio',
    amountText: 'Importe',
    includes: 'Incluye',
    commission: '{{commission}}% comisión',
    howItWorks: '',
    banksComapreOpen: 'Comparar con otros',
    banksComapreClose: 'Colapsar la lista',
    savingsMonthly: 'Ahorra incluso una vez con Walutomat',
    savingsYearly: 'sustituyendo durante todo el año, todos los meses.',
    conjunction: 'y',
    serverError: 'Error de comunicación del servidor',
    unsupportedPair: 'Actualmente no ofrecemos tarifas para el par de divisas seleccionado.',
    infoError: {
      exchange: 'En Walutomat.pl puede intercambiar directamente',
      transactionFee:
        'y viceversa. Tal transacción en el banco se asocia con una alta tasa, por lo general llega incluso a 5-10% de la cantidad de cambio.',
    },
    spinnerError: 'Hubo un problema con la descarga de datos',
  },
}
