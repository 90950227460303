export default {
  calculator: {
    title: 'Einsparungen im Walutomat berechnen',
    rangeFrom: 'Ich möchte',
    forBuy: 'für',
    forSell: 'an',
    directionBuy: 'Kaufen',
    directionSell: 'Verkaufen',
    payText: 'Sie zahlen',
    sellText: 'Sie erhalten',
    buySelect: 'Kaufen',
    sellSelect: 'Verkaufen',
    rateText: 'Wechselkurs',
    amountText: 'Betrag',
    includes: 'Enthält',
    commission: '{{commission}}% Provision',
    howItWorks: '',
    banksComapreOpen: 'Mit anderen vergleichen',
    banksComapreClose: 'Liste minimieren',
    savingsMonthly: 'Sie sparen sogar einmal mit Walutomat',
    savingsYearly: 'ersetzt das ganze Jahr über, jeden Monat.',
    conjunction: 'und',
    serverError: 'Server-Kommunikationsfehler',
    unsupportedPair: 'Wir bieten derzeit keine Kurse für das ausgewählte Währungspaar an.',
    infoError: {
      exchange: 'Bei Walutomat.pl können Sie direkt umtauschen',
      transactionFee:
        'und zurück. Eine solche Transaktion bei der Bank ist mit einer hohen Gebühr verbunden, die in der Regel sogar 5-10 % des Umtauschbetrags erreicht.',
    },
    spinnerError: 'Beim Herunterladen der Daten ist ein Fehler aufgetreten',
  },
}
