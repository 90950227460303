export default {
  calculator: {
    title: 'Calculați economiile în Walutomat',
    rangeFrom: 'Vreau să',
    forBuy: 'pentru',
    forSell: 'pentru',
    directionBuy: 'Să cumpăr',
    directionSell: 'Vânzare',
    payText: 'veți plăti',
    sellText: 'veți primi',
    buySelect: 'cumpărați',
    sellSelect: 'vinde',
    rateText: 'Rata de schimb',
    amountText: 'Suma',
    includes: 'Include',
    commission: '{{commission}}% comision',
    howItWorks: '',
    banksComapreOpen: 'Comparați cu alții',
    banksComapreClose: 'Închideți lista',
    savingsMonthly: 'Economisiți chiar și o singură dată cu Walutomat',
    savingsYearly: 'înlocuind pe tot parcursul anului, în fiecare lună.',
    conjunction: 'și',
    serverError: 'Eroare de comunicare cu serverul',
    unsupportedPair: 'În prezent nu oferim rate pentru perechea valutară selectată.',
    infoError: {
      exchange: 'La Walutomat.pl puteți schimba direct',
      transactionFee:
        'și înapoi. O astfel de tranzacție la bancă este asociată cu un comision ridicat, ajungând de obicei chiar și la 5-10% din suma de schimb.',
    },
    spinnerError: 'A existat o problemă cu descărcarea datelor',
  },
}
