export default {
  calculator: {
    title: 'Výpočet úspor v aplikaci Walutomat',
    rangeFrom: 'Chci',
    forBuy: 'pro',
    forSell: 'na',
    directionBuy: 'Nákup',
    directionSell: 'Prodej',
    payText: 'zaplatíte',
    sellText: 'obdržíte',
    buySelect: 'koupit',
    sellSelect: 'prodat',
    rateText: 'Směnný kurz',
    amountText: 'Částka',
    includes: 'Včetně',
    commission: '{{commission}}% provize',
    howItWorks: '',
    banksComapreOpen: 'Porovnání s ostatními',
    banksComapreClose: 'Sbalit seznam',
    savingsMonthly: 'S Walutomat ušetříte i jednou',
    savingsYearly: 'nahrazující po celý rok, každý měsíc.',
    conjunction: 'a',
    serverError: 'Chyba komunikace se serverem',
    unsupportedPair: 'V současné době nenabízíme kurzy pro vybraný měnový pár.',
    infoError: {
      exchange: 'Na Walutomat.pl můžete přímo směňovat',
      transactionFee:
        'a zpět. Taková transakce v bance je spojena s vysokým poplatkem, který obvykle dosahuje i 5-10 % směňované částky.',
    },
    spinnerError: 'Při stahování dat došlo k problému',
  },
}
