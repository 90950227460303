export default {
  calculator: {
    title: "Calculer l'épargne en Walutomat",
    rangeFrom: 'Je souhaite',
    forBuy: 'pour',
    forSell: 'à',
    directionBuy: 'Acheter',
    directionSell: 'Vendre',
    payText: 'vous paierez',
    sellText: 'vous recevrez',
    buySelect: 'acheter',
    sellSelect: 'vendre',
    rateText: 'Taux de change',
    amountText: 'Montant',
    includes: 'Comprend',
    commission: '{{commission}}% commission',
    howItWorks: '',
    banksComapreOpen: "Comparer avec d'autres",
    banksComapreClose: 'Réduire la liste',
    savingsMonthly: "Avec Walutomat, vous n'économisez qu'une seule fois",
    savingsYearly: "remplaçant tout au long de l'année, chaque mois.",
    conjunction: 'et',
    serverError: 'Erreur de communication du serveur',
    unsupportedPair: "Nous n'offrons pas actuellement de taux pour la paire de devises sélectionnée.",
    infoError: {
      exchange: 'Sur Walutomat.pl vous pouvez directement échanger',
      transactionFee:
        "et inversement. Une telle transaction à la banque est associée à des frais élevés, atteignant généralement 5 à 10 % du montant de l'échange.",
    },
    spinnerError: 'Il y a eu un problème avec le téléchargement des données',
  },
}
